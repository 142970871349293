<template>
  <div class="ht-slider">
    <b-container
      fluid
      class="slider-container"
    >
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <slot name="content" />
        </b-col>
        <b-col
          class="text-center"
          cols="12"
          lg="4"
        >
          <slot name="text" />

        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="text-end"
        >
          <slot name="image" />
        </b-col>
      </b-row>
    </b-container>
    <ht-wave />
  </div>
</template>
<script>

import HtWave from './HtWave.vue'

export default {

  name: 'HtSlider',
  components: { HtWave },
}
</script>
