<template>
  <vs-row vs-w="12">
    <vs-col
      vs-w="12"
      class="min-ht-slider"
    >
      <ht-slider />
    </vs-col>
    <vs-col class="mb-5">
      <div>
        <b-container>
          <slot />
        </b-container>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import HtSlider from '@/views/home/component/HtSlider.vue'

export default {
  name: 'MinWave',
  components: {
    HtSlider,
  },
  data() {
    return {}
  },
}
</script>
