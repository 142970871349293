<template>
  <div />
</template>
<script>

export default {
  name: 'SliderLogo',
}
</script>
<style scoped>

</style>
