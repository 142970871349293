<template>
  <div class="homeContent home-nav">
    <b-navbar
      class="home-main-navbar"
      toggleable="lg"
    >
      <b-navbar-brand
        href="#"
        class="mr-5 ml-5"
      >
        <span class="brand-logo mr-5 ml-5">
          <slider-logo />
          <b class="mr-5 ml-5" />
        </span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav class="home-b-navbar-nav ml-5">
          <b-nav-item
            :class="getStatus('home')"
            href="#"
            @click="$router.push({name:'home'})"
          >
            {{ $t('Home') }}
          </b-nav-item>
          <b-nav-item
            :class="getStatus('jobs')"
            href="#"
            @click="$router.push({name:'jobs'})"
          >
            {{ $t('Jobs') }}
          </b-nav-item><b-nav-item
            href="#"
            :class="getStatus('candidates')"
            @click="$router.push({name:'candidates'})"
          >
            {{ $t('Candidates') }}
          </b-nav-item>
          <b-nav-item
            href="#AboutUs"
          >
            {{ $t('About Us') }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="nav align-items-center ml-auto">
          <b-nav-item href="#" class="darkToggler">
            <div class="bookmark-wrapper align-items-center flex-grow-1  d-lg-flex">
              <dark-Toggler class="d-lg-block" />
            </div>
          </b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              <b-avatar
                size="40"
                badge
                :src="$fullImagePath( authAvatar )"
                class="badge-minimal"
                badge-variant="success"
              />
            </template>
            <div v-if="!isAuth">
              <b-dropdown-item
                href="#"
                @click="goTo('login')"
              >
                <fa-icon
                  class="mr-50"
                  icon="sign-in-alt"
                  pack="fas"
                />
                {{ $t('Login') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="EnvRegister"
                href="#"
                @click="goTo('register')"
              >
                <fa-icon
                  class="mr-50"
                  icon="user-plus"
                  pack="fas"
                />
                {{ $t('Register') }}
              </b-dropdown-item>
            </div>
            <div v-else>
              <b-dropdown-item
                href="#"
                @click="goTo('dashboard')"
              >
                <fa-icon
                  icon="tachometer-alt"
                  class="mr-50"
                  pack="fas"
                />
                {{ $t('Dashboard') }}
              </b-dropdown-item>
              <b-dropdown-item
                href="#"
                @click="goTo('UserProfile')"
              >
                <fa-icon
                  icon="user"
                  pack="fas"
                />
                {{ $t('Profile') }}
              </b-dropdown-item>
              <b-dropdown-item
                href="#"
                @click="goTo('UserDevices')"
              >
                <fa-icon
                  icon="laptop"
                  class="mr-50"
                  pack="fas"
                />
                {{ $t('Devices') }}
              </b-dropdown-item>
              <b-dropdown-item
                href="#"
                @click="logout()"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                {{ $t('Logout') }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="padding-footer">
      <transition
        name="fade"
        mode="out-in"
      >
        <ht-full-slider
          v-if="$route.meta.full_wave"
        />
        <min-wave
          v-else
        />
      </transition>

      <transition
        :name="routerTransition"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <ht-footer class="ht-footer" />
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import { $themeConfig } from '@themeConfig'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import MinWave from '@/views/components/min-wave.vue'
import HtFullSlider from '@/views/home/component/HtFullSlider.vue'
import SliderLogo from '../components/SliderLogo.vue'

export default {
  components: {
    HtFullSlider,
    MinWave,
    DarkToggler,
    SliderLogo,
  },
  data() {
    return {
      /* eslint-disable global-require */
      AllLocale: {
        en: {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
        ar: {
          locale: 'ar',
          img: require('@/assets/images/flags/ar.jpg'),
          name: 'Arabic',
        },
        tr: {
          locale: 'tr',
          img: require('@/assets/images/flags/tr.jpg'),
          name: 'Turkish',
        }
        ,
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const {
      routerTransition,
    } = useAppConfig()
    return {
      appName,
      appLogoImage,
      routerTransition,
    }
  },
  computed: {
    locales() {
      const locale = { ...this.AllLocale }
      delete locale[this.$i18n.locale]
      return locale
    },
    authAvatar() {
      return this.$store.getters['auth/GetAuthAvatar']
    },
    auth() {
      return this.$store.getters['auth/GetAuth']
    },
    isAuth() {
      if (this.$auth.GetAuth()) return true
      return false
    },
    siteTitle() {
      return this.$store.getters['app/title']
    },
  },
  methods: {
    getStatus(page) {
      if (this.$route.name === page) return 'active'
      return ''
    },
    changeLanguage(locale) {
      localStorage.setItem('local', locale)
      this.$i18n.locale = locale
      if (this.$i18n.locale === 'ar') {
        this.$vs.rtl = true
        this.$store.dispatch('appConfig/toggle_rtl', true)
      } else {
        this.$vs.rtl = false
        this.$store.dispatch('appConfig/toggle_rtl', false)
      }
    },
    getLocal() {
      return this.AllLocale[this.$i18n.locale].name
    },
    getLocalImage() {
      return this.AllLocale[this.$i18n.locale].img
    },
    goTo(val) {
      this.$router.push({
        name: val,
      })
    },
    logout() {
      this.$store.dispatch('auth/Logout', false)
      this.$router.push({
        name: 'login',
      })
    },
  },
}
</script>
