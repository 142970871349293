<template>
  <div class="ht-slider-wave">
    <svg
      id="Layer_1"
      class="ht-wave"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 1920 174.65"
    >
      <g
        id="Layer_1-2"
        data-name="Layer_1"
      >
        <path
          class="ht-wave-path-cls1 cls-1"
        />
        <path
          class="ht-wave-path-cls2 cls-2"
        />
        <path
          class="ht-wave-path-cls3 cls-3"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'HtWave',
}
</script>

<style type="text/css">

svg.ht-wave path {
  transform: translate(0px, -246px);
}

[dir=rtl] .ht-wave-path-cls1 {

  d: path("M888.14,353.84c31.83,4.24,83.36,10.93,174.12,13.7s220.74,1.55,353.43-11,267.94-36.36,388-71.87C1844.81,272.54,1884.13,259,1920,246v71.85c-35.28,9.81-72,18.67-110.07,26.52-87.13,18.07-206.43,36.87-331.24,44s-255.1,2.55-384.15-9.49c-79.83-7.44-159.18-17.74-234.81-28.75,7.37.89,16.52,2.13,28.41,3.73Z")
}

[dir=rtl] .ht-wave-path-cls2 {
  d: path("M0,281.85c21.11-4.61,43.78-9.08,72.39-13a978.43,978.43,0,0,1,148-8.83c59.16.9,127.55,7.06,210.67,17.61S612,303.13,724.87,319.5s240.62,34.1,369.67,44.36,259.35,13,384.15,4.21,244.11-29.24,331.24-48.53c38.06-8.38,74.83-17.75,110.07-28v26.34c-35.28,9.81-72,18.67-110.07,26.51-87.13,18.07-206.43,36.87-331.24,44s-255.1,2.55-384.15-9.48S837.69,347.3,724.87,329.42,514.2,295.24,431.08,283.53,279.57,264.75,220.41,263a975.22,975.22,0,0,0-148,6.79c-28.61,3.54-51.28,7.7-72.39,12Z")
}

[dir=rtl] .ht-wave-path-cls3 {
  d: path("M0,281.68c21.11-4.32,43.78-8.48,72.39-12a976.59,976.59,0,0,1,148-6.78c59.16,1.72,127.55,8.81,210.67,20.52s180.94,28,293.81,45.93,240.62,37.42,369.67,49.47,259.35,16.61,384.15,9.52,244.11-25.9,331.24-44c38-7.84,74.79-16.7,110.07-26.5V420.65H0Z")
}

.ht-wave-path-cls1 {
  d: path("M1031.86,353.84c-31.83,4.24-83.36,10.93-174.12,13.7s-220.74,1.55-353.43-11-267.94-36.36-388-71.87C75.19,272.54,35.87,259,0,246v71.85c35.28,9.81,72.05,18.67,110.07,26.52,87.13,18.07,206.43,36.87,331.24,44s255.1,2.55,384.15-9.49c79.83-7.44,159.18-17.74,234.81-28.75-7.37.89-16.52,2.13-28.41,3.73Z")
}

.ht-wave-path-cls2 {
  d: path("M1920,281.85c-21.11-4.61-43.78-9.08-72.39-13a978.43,978.43,0,0,0-148-8.83c-59.16.9-127.55,7.06-210.67,17.61S1308,303.13,1195.13,319.5s-240.62,34.1-369.67,44.36-259.35,13-384.15,4.21S197.2,338.83,110.07,319.54C72,311.16,35.24,301.79,0,291.5v26.34c35.28,9.81,72.05,18.67,110.07,26.51,87.13,18.07,206.43,36.87,331.24,44s255.1,2.55,384.15-9.48,256.85-31.56,369.67-49.44,210.67-34.18,293.79-45.89,151.51-18.78,210.67-20.49a975.22,975.22,0,0,1,148,6.79c28.61,3.54,51.28,7.7,72.39,12Z")
}

.ht-wave-path-cls3 {
  d: path("M1920,281.68c-21.11-4.32-43.78-8.48-72.39-12a976.59,976.59,0,0,0-148-6.78c-59.16,1.72-127.55,8.81-210.67,20.52s-180.94,28-293.81,45.93S954.51,366.75,825.46,378.8s-259.35,16.61-384.15,9.52-244.11-25.9-331.24-44c-38-7.84-74.79-16.7-110.07-26.5V420.65H1920Z")
}

.ht-slider-wave .cls-1 {

  opacity: 0.25;
}

.ht-slider-wave .cls-2 {
  opacity: 0.5;
}
</style>
