<template>
  <div>
    <ht-slider>
      <template #content>
        <img
          width="60%"
          class="slidlogo"
          :src="require('@/assets/images/HT/darklogo.png')"
        >
      </template>
      <template #text>
        <h3 class="slider-title">
          We bridge the gaps between refugees and Irish companies and connect refugees talent with Irish companies
        </h3>
        <br>
        <a href="#content_section">
          <div class="explor-more">
            <fa-icon
              pack="fas"
              color="primary"
              icon="arrow-circle-down"
              size="3"
            />
            <h2>Explore More</h2>
          </div>
        </a>
      </template>
    </ht-slider>
  </div>
</template>
<script>
import HtSlider from '@/views/home/component/HtSlider.vue'

export default {
  components: { HtSlider },
  computed: {
    sliderText() {
      return this.$store.getters['app/sliderText']
    },
  },
}
</script>
